




















































import { Vue, Component } from 'vue-property-decorator';

@Component({
  head: {
    title() {
      return {
        inner: '제3자 마케팅 활용 동의'
      }
    }
  }
})
export default class Marketing extends Vue {
  get isApp() {
    if (this.$route.query._from) return true;
    else return false;
  }
  get domain() {
    return location.origin;
  }
}
